import {
	Loading,
	Message
} from "element-ui"
import store from "@/store"

const hostname = process.env.NODE_ENV === "production" ? "//www.jingdingysh.com" : "";

// GET 请求
export function get(options) {
	return new Promise((resolve, reject) => {
		const loading = options.loading && Loading.service({
			target: ".el-main"
		});
		const xhr = new XMLHttpRequest();
		const searchParams = new URLSearchParams();
		for (const key in options.params) {
			searchParams.append(key, options.params[key]);
		}
		xhr.onreadystatechange = function() {
			if (xhr.readyState === xhr.DONE) {
				loading?.close();
				if (xhr.status === 200) {
					const response = xhr.response;
					if (options.export) {
						resolve(response)
						return
					}
					if (response.code === 1) {
						options.successTip && Message.success(response.msg);
						resolve(response.data);
					} else if (response.code === 302) {
						Message.error(response.msg);
						store.commit("logout");
						reject();
					} else if (response.code === 409) {
						Message.error(response.msg);
						store.commit("logout");
						reject();
					} else {
						!options.noFailTip && Message.error(response.msg);
						reject(response);
					}
				} else {
					Message.error(xhr.statusText);
					reject();
				}
			}
		}
		xhr.responseType = "json";
		xhr.open("GET", `${hostname}${options.url}${options.params?'?':''}${searchParams.toString()}`);
		xhr.setRequestHeader("batoken", store.state.token);
		if (options.export) {
			xhr.responseType = "blob";
		}
		xhr.send(null);
	})
}

// POST
export function post(options) {
	return new Promise((resolve, reject) => {
		const loading = options.loading && Loading.service({
			target: ".el-main"
		});
		const xhr = new XMLHttpRequest();
		xhr.onreadystatechange = function() {
			if (xhr.readyState === xhr.DONE) {
				if (xhr.status === 200) {
					loading?.close();
					const response = xhr.response;
					// if (options.export) {
					// 	resolve(response)
					// 	return
					// }
					if (response.code === 1) {
						options.successTip && Message.success(response.msg);
						resolve(response.data);
					} else if (response.code === 302) {
						Message.error(response.msg);
						store.commit("logout");
						reject();
					} else if (response.code === 409) {
						Message.error(response.msg);
						store.commit("logout");
						reject();
					} else {
						Message.error(response.msg);
						reject();
					}
				} else {
					Message.error(xhr.statusText);
					reject();
				}
			}
		}
		xhr.responseType = "json";
		xhr.open("POST", `${hostname}${options.url}`);
		xhr.setRequestHeader("batoken", store.state.token);
		xhr.setRequestHeader("Content-Type", "application/json");
		// if (options.export) {
		// 	xhr.responseType = "blob";
		// }
		xhr.send(JSON.stringify(options.data));
	})
}

// DELETE
export function del(options) {
	return new Promise((resolve, reject) => {
		const loading = options.loading && Loading.service({
			target: ".el-main"
		});
		const xhr = new XMLHttpRequest();
		const searchParams = new URLSearchParams();
		for (const key in options.data) {
			searchParams.append(key, options.data[key]);
		}
		xhr.onreadystatechange = function() {
			if (xhr.readyState === xhr.DONE) {
				if (xhr.status === 200) {
					loading?.close();
					const response = xhr.response;
					if (response.code === 1) {
						options.successTip && Message.success(response.msg);
						resolve(response.data);
					} else if (response.code === 302) {
						Message.error(response.msg);
						store.commit("logout");
						reject();
					} else if (response.code === 409) {
						Message.error(response.msg);
						store.commit("logout");
						reject();
					} else {
						Message.error(response.msg);
						reject();
					}
				} else {
					Message.error(xhr.statusText);
					reject();
				}
			}
		}
		xhr.responseType = "json";
		xhr.open("DELETE", `${hostname}${options.url}`);
		xhr.setRequestHeader("batoken", store.state.token);
		xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
		xhr.send(searchParams);
	})
}

// POST
export function imghttp(options) {
	return new Promise((resolve, reject) => {
		const loading = options.loading && Loading.service({
			target: ".el-main"
		});
		const xhr = new XMLHttpRequest();
		let formData = new FormData()
		formData.append('file', options.data.file)
		xhr.onreadystatechange = function() {
			if (xhr.readyState === xhr.DONE) {
				loading?.close();
				if (!loading) {
					Loading.service().close()
				}
				if (xhr.status === 200) {
					const response = xhr.response;
					if (response.code === 1) {
						options.successTip && Message.success(response.msg);
						resolve(response.data);
					} else if (response.code === 302) {
						Message.error(response.msg);
						store.commit("logout");
						reject();
					} else if (response.code === 409) {
						Message.error(response.msg);
						store.commit("logout");
						reject();
					} else {
						!options.noFailTip && Message.error(response.msg);
						reject(response);
					}
				} else {
					loading?.close();
					Message.error(xhr.statusText);
					reject();
				}
			}
		}
		xhr.responseType = "json";
		xhr.open("POST", `${hostname}${options.url}`);
		xhr.setRequestHeader("batoken", store.state.token);
		xhr.send(formData);
	})
}